<template>
  <div class="h-screen flex w-full bg-img">
    <div class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
      <img src="@/assets/images/pages/maintenance-2.png" alt="graphic-maintenance" class="mx-auto mb-4">
      <h1 class="sm:mx-0 mx-4 mb-6 text-5xl d-theme-heading-color">Under Maintenance!</h1>
      <p class="sm:mx-0 mx-4 mb-6">Access to this portal is currently unavailable due to maintenance. Please try again later.</p>
    </div>
  </div>
</template>

<script>
export default {}
</script>

